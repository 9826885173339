
  /**
   * this file was autogenerated by the icon-renderer module
   * the settings for this are in the nuxt.config.ts file
   * if you would like to override the contents in this file you can create a custom component in the
   * assets/icons directory and name it the same as the icon you would like to override
   * */
  <template>
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M16.8873 5.53119C16.8816 5.50482 16.8816 5.47755 16.8873 5.45119C16.8824 5.42811 16.8824 5.40427 16.8873 5.38119V5.29119L16.8273 5.14119C16.8029 5.10026 16.7726 5.06319 16.7373 5.03119L16.6473 4.95119H16.5973L12.6573 2.46119L8.93728 0.161189C8.85122 0.0929191 8.75274 0.0419794 8.64728 0.0111891H8.56728C8.4779 -0.00372972 8.38667 -0.00372972 8.29728 0.0111891H8.19728C8.08113 0.0368814 7.96976 0.0807551 7.86729 0.141189L0.397285 4.79119L0.307285 4.86119L0.217285 4.94119L0.117285 5.01119L0.067285 5.07119L0.00728483 5.22119V5.31119V5.37119C-0.00242828 5.4375 -0.00242828 5.50488 0.00728483 5.57119V14.3012C0.00694475 14.4711 0.049921 14.6384 0.132156 14.7871C0.214391 14.9358 0.333172 15.0611 0.477285 15.1512L7.97728 19.7912L8.12728 19.8512H8.20728C8.37647 19.9048 8.5581 19.9048 8.72728 19.8512H8.80728L8.95728 19.7912L16.3973 15.2212C16.5414 15.1311 16.6602 15.0058 16.7424 14.8571C16.8246 14.7084 16.8676 14.5411 16.8673 14.3712V5.64119C16.8673 5.64119 16.8873 5.57119 16.8873 5.53119ZM8.39728 2.18119L10.1773 3.28119L4.58728 6.74119L2.79729 5.64119L8.39728 2.18119ZM7.39728 17.1812L1.89728 13.8212V7.43119L7.39728 10.8312V17.1812ZM8.39728 9.07119L6.48729 7.92119L12.0773 4.45119L13.9973 5.64119L8.39728 9.07119ZM14.8973 13.7912L9.39728 17.2112V10.8312L14.8973 7.43119V13.7912Z" fill="#6563FF"/>
</svg>

  </template>

  <script>
  export default {
    name: 'Package',
    inheritAttrs: true,
  }
  </script>
